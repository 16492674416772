hr {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: lightgray; /* Modern Browsers */
}

.modal-base {
  top: 2%;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  border-radius: 0.25em;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  opacity: 0;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  transform: scale(0.8);
}

.modal-base:focus {
  outline: 0;
}

.modal-base_after-open {
  opacity: 1;
  transform: scale(1);
}

.modal-base_before-close {
  transform: scale(0.2);
  opacity: 0;
  transition: all 0.1s ease-out;
}

.overlay-base {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.1s ease-out;
}

.overlay-base_after-open {
  opacity: 1;
}

.overlay-base_before-close {
  opacity: 0;
  transition: all 0.1s ease-out;
}
