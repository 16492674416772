.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.container-h3 {
  font-size: 1.825rem;
  font-weight: 300;
  line-height: 1.167;
}
